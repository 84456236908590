<template>
	<div>
		<Header />
		<div class="content">
			<MainColumn>
				<img class="picks" :src="require('../assets/picks.svg')" />
				<div class="content-404">
					<div class="vertical-align">
						<h1 class="color-aili-white text-404">404</h1>
						<h2 class="color-aili-white chantal">
							Pagina non trovata, Forse contiene lattosio!
						</h2>
					</div>
				</div>
			</MainColumn>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';

export default {
	name: 'Home',
	components: {
		Header,
	},
	metaInfo() {
		return {
			meta: [{ name: 'robots', content: 'noindex' }],
		};
	},
	data() {
		return {};
	},

	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-blue',
		});
		this.updateProperty({
			property: 'loadingData',
			value: false,
		});
	},
};
</script>
<style lang="scss" scoped>
#app {
	.picks {
		position: absolute;
		width: 100%;
		margin-top: -100px;
		height: 100%;
	}
	.content-404 {
		.vertical-align {
			display: inline-block;
			position: absolute;
			left: 50%;
			max-width: 380px;
			top: calc(50% - 100px);
			transform: translate(-50%, -50%);
			width: 100%;
		}
		min-height: calc(100vh - 200px);
		display: inline-flex;
		h1 {
			font-size: 150px;
			@media (max-width: $tablet-m) {
				font-size: 80px;
			}
		}
		h2 {
			font-size: 30px;
		}
		text-align: center;
		max-width: 380px;
		margin: auto;
	}
}
</style>
